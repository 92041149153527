/*Extra Large Screens*/
@media (min-width: 1600px) {
    .Superadmin_profileview_title{
      font-size:50px; 
      font-weight:800;
      font-family:'poppins extrabold';
    }
    .Superadmin_profileviewdetails {
      max-width: 1040px;
    }
    .Superadmin_ProfileImage img{
      width: 80%;
    }
  }
  /*Large Screens*/
  @media (min-width: 1200px) and (max-width: 1599px) {
    .Superadmin_profileview_title{
      font-size:50px; 
      font-weight:800;
      font-family:'poppins extrabold';
    }
    .Superadmin_Profiledetails{
      max-width: 1040px;
    }
    .Superadmin_ProfileImage img{
      width: 80%;
    }
  }
  /*Medium Screens*/
  @media (min-width: 992px) and (max-width: 1199px) { 
    .Superadmin_profileview_title{
      font-size:46px; 
      font-weight:800;
      font-family:'poppins extrabold';
    }
    .Superadmin_Profiledetails{
      max-width: 940px;
    }
    .Superadmin_ProfileImage img{
      width: 80%;
    }
  }
  
  /*Small Screens (Portriat Mode)*/
  @media (min-width: 768px) and (max-width: 991px) { 
    .Superadmin_profileview_title{
      font-size:40px; 
      font-weight:800;
      font-family:'poppins extrabold';
    }
     .Superadmin_Profiledetails{
      max-width: 740px;
    }
    .Superadmin_ProfileImage img{
      width: 80%;
    }
  }
  
  /*Extra Small Screens (Landscape Mode)*/
  @media (min-width: 576px) and (max-width: 767px) { 
    .Superadmin_profileview_title{
      font-size:38px; 
      font-weight:800;
      font-family:'poppins extrabold';
    }
    .Superadmin_Profiledetails{
      max-width: 400px;
    }
    .Superadmin_ProfileImage img{
      width: 40%;
    }
  }
  
  /*Extra Small Screens (Portriat Mode)*/
  @media (min-width:320px) and (max-width: 575px){ 
    .Superadmin_profileview_title{
      font-size:34px; 
      font-weight:800;
      font-family:'poppins extrabold';
    }
    .Superadmin_Profiledetails{
      max-width: 300px;
    }
    .Superadmin_ProfileImage img{
      width: 40%;
    }
  }