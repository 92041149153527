/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/


/*nav.shrink {

    background-color: rgba(120,120,120,0.6);
    padding-top:0px;
    padding-bottom:0px;
    border-bottom: 0.2px solid grey;
  }
  nav {
    padding-top:0px;
    padding-bottom:0px;
  }
  
  
   #header nav.shrink #logo {
     height:60px;
     width:60px;
   }
   #header #logo {
     height:60px;
     width:60px;
   }
   @media (min-width:992px) {
   nav.shrink {

     background-color: rgba(120,120,120,0.6);
     padding-top:0px;
     padding-bottom:0px;
     border-bottom: 0.2px solid grey;
   }
   nav {
     padding-top:0px;
     padding-bottom:0px;
   }
  
  
    #header nav.shrink #logo {
      height:60px;
      width:60px;
    }
    #header #logo {
      height:60px;
      width:60px;
    }
  
  
    nav, nav.shrink #navbarNav ul li a{
      padding-top:10px;
      font-size: 14px;
      margin: 0;
      padding: 8px;
      line-height: 1;

      font-weight: 700;
      letter-spacing: 1px;
      color:#707070;
    }
    nav #navbarNav ul li a{
      padding-top:20px;
      font-size: 14px;
      margin: 0;
      padding: 8px;
      line-height: 1;

      font-weight: 700;
      letter-spacing: 1px;

      color:#ffffff;
    }
   }
  
   @media (min-width: 768px) and (max-width: 991px) {
  
        nav, nav.shrink {
         background-color: white;
         padding-top:20px;
         padding-bottom:10px;
         border-bottom: 0.2px solid grey;
         color: #707070;

       }
       #header nav #logo, #header nav.shrink #logo {
         height:60px;
         width:60px;
       }
  
  
       nav, nav.shrink #navbarNav ul li a{
         padding-top:20px;
         font-size: 14px;
         margin: 0;
         padding: 8px;
         line-height: 1;

         font-weight: 700;
         letter-spacing: 1px;

 
         color: #2f2284;
       }
       nav #navbarNav ul li a{
         padding-top:20px;
         font-size: 14px;
         margin: 0;
         padding: 8px;
         line-height: 1;

         font-weight: 700;
         letter-spacing: 1px;
  
  
         color: #2f2284;
       }
  
  }
  @media (min-width: 576px) and (max-width: 767px) {
    nav, nav.shrink {
     background-color: white;
     padding-top:20px;
     padding-bottom:10px;
     border-bottom: 0.2px solid grey;
     color: #707070;
  

   }
   #header nav #logo, #header nav.shrink #logo {
     height:60px;
     width:60px;
   }
  
  
   nav, nav.shrink #navbarNav ul li a{
     padding-top:20px;
     font-size: 14px;
     margin: 0;
     padding: 8px;
     line-height: 1;

     font-weight: 700;
     letter-spacing: 1px;

     color: #2f2284;
   }
   nav #navbarNav ul li a{
     padding-top:20px;
     font-size: 14px;
     margin: 0;
     padding: 8px;
     line-height: 1;

     font-weight: 700;
     letter-spacing: 1px;

    
     color: #2f2284;
   }
  }
  

  @media (min-width:320px) and (max-width: 575px){
    nav, nav.shrink {
     background-color: white;
     padding-top:20px;
     padding-bottom:10px;
     border-bottom: 0.2px solid grey;
     color: #707070;
  

   }
   #header nav #logo, #header nav.shrink #logo {
     height:60px;
     width:60px;
   }
  
  
   nav, nav.shrink #navbarNav ul li a{
     padding-top:20px;
     font-size: 14px;
     margin: 0;
     padding: 8px;
     line-height: 1;

     font-weight: 700;
     letter-spacing: 1px;

 
     color: #2f2284;
   }
   nav #navbarNav ul li a{
     padding-top:20px;
     font-size: 14px;
     margin: 0;
     padding: 8px;
     line-height: 1;

     font-weight: 700;
     letter-spacing: 1px;

   
     color: #2f2284;
   }
  }
  @media only screen and (min-device-width : 320px) and (max-device-width : 767px) {
  
  
     nav, nav.shrink {
      background-color: white;
      padding-top:20px;
      padding-bottom:10px;
      border-bottom: 0.2px solid grey;
      color: #707070;
  
     
    }
    #header nav #logo, #header nav.shrink #logo {
      height:60px;
      width:60px;
    }
  
  
    nav, nav.shrink #navbarNav ul li a{
      padding-top:20px;
      font-size: 14px;
      margin: 0;
      padding: 8px;
      line-height: 1;

      font-weight: 700;
      letter-spacing: 1px;

 
      color: #2f2284;
    }
    nav #navbarNav ul li a{
      padding-top:20px;
      font-size: 14px;
      margin: 0;
      padding: 8px;
      line-height: 1;

      font-weight: 700;
      letter-spacing: 1px;

     
      color: #2f2284;
    }
  
  }
  
  #mainNav .navbar-nav .nav-item {
    -webkit-transition: background .3s ease-in-out;
    -moz-transition: background .3s ease-in-out;
    transition: background .3s ease-in-out
  }
  
  #mainNav .navbar-nav .nav-item:hover {
    background-color: transparent;
    color: #2f2284;
  font-weight: 700;
  }
  
  #mainNav .navbar-nav .nav-item:active, #mainNav .navbar-nav .nav-item:focus {
    color: #2f2284;
    font-weight: 700;
  }
  
  #mainNav .nav-link.active:hover, #mainNav .nav-link:hover, #mainNav .nav-link.active {
    color: #2f2284;
    font-weight: 700;
  }
  
  #mainNav .navbar-toggler {
    font-size: 14px;
    padding: 0px;
    color: #000000;
    border: 1px solid #000000;
  }
  
  .navbar-toggler{
    float:right;
    text-align: right;
  }
  
  /*Extra Large Screens*/
  @media (min-width: 1600px) {
    .superadminprofile_details{
        margin-left: 15%;
        margin-right: 15%;
    }
    .superadminprofile_selectimage img{
        width: 10%;
        border-radius: 50px;
        border:5px solid #ccc;
    }
    .superadminprofile_savebtn button{
        width: 15%;
        }
    }
    /*Large Screens*/
    @media (min-width: 1200px) and (max-width: 1599px) {
        .superadminprofile_details{
            margin-left: 15%;
            margin-right: 15%;
        }
        .superadminprofile_savebtn button{
            width: 15%;
        }
        .superadminprofile_selectimage img{
            width: 10%;
            border-radius: 50px;
            border:5px solid #ccc;
        }	
    }
    /*Medium Screens*/
    @media (min-width: 992px) and (max-width: 1199px) { 
       .superadminprofile_details{
            margin-left: 10%;
            margin-right: 10%;
        }
        .superadminprofile_savebtn button{
            width: 15%;
        }
        .superadminprofile_selectimage img{
            width: 15%;
            border-radius: 50px;
            border:5px solid #ccc;
        }
    }
    
    /*Small Screens (Portriat Mode)*/
    @media (min-width: 768px) and (max-width: 991px) { 
        .superadminprofile_savebtn button{
            width: 20%;
        }
        .superadminprofile_selectimage img{
            width: 20%;
            border-radius: 100px;
            border:5px solid #ccc;
        }
    }
    
    /*Extra Small Screens (Landscape Mode)*/
    @media (min-width: 576px) and (max-width: 767px) { 
        .superadminprofile_savebtn button{
            width: 25%;
        }
        .superadminprofile_selectimage img{
            width: 25%;
            border-radius: 100px;
            border:5px solid #ccc;
        }
    }
    
    /*Extra Small Screens (Portriat Mode)*/
    @media (min-width:320px) and (max-width: 575px){ 
        .superadminprofile_savebtn button{
        width: 40%;
        }
        .superadminprofile_selectimage img{
            width: 30%;
            border-radius: 100px;
            border:5px solid #ccc;
        }
    }
    