body{
  font-family: poppins;
}
.PaymentDashboard-Details{
  margin-top: 90px;
}
.PaymentDashboard-filter select{
  border: 2px solid #105c25;
  border-radius: 5px;
}
.PaymentDashboard-title h3{
  font-family: poppins semibold;
}
.PaymentDashboard-title span{
  color: #999;
  font-size: 14px;
}
:focus {
  outline: -webkit-focus-ring-color auto 0px;
}
.total-transaction:hover, .successful-transaction:hover, .total-payments:hover{
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
}
.total-transaction{
  background-color: #11772e;
  color: #fff;
  border: 2px solid #11772e;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
}
.total-transaction h3, .successful-transaction h3, .total-payments h3 span{
  font-family: poppins semibold;
}
.total-payments h3{
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
}
.total-transaction span{
  font-family: poppins medium;
  color: #C4C4C4;
  font-size: 14px;
  margin-bottom: 10px;
}
.total-transaction .today-update{
  font-family: poppins regular;
  color:#C4C4C4;
}
.successful-transaction, .total-payments{
  background-color: #fff;
  border: 2px solid #11772e;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
}
.successful-transaction span, .total-payments .total-payments-toptext, .today-update{
  font-family: poppins medium;
  color: #A1A1A1;
  font-size: 14px;
  margin-bottom: 10px;
}
.successful-transaction .today-update, .total-payments .today-update{
  font-family: poppins regular;
  color: #24B146;
}
.PaymentDashboard-transaction-table{
  margin-top: 30px;
}
.transaction-table-info .transactionid-hover:hover,.transaction-table-info .appointment-details:hover{
 cursor: pointer;
 text-decoration: underline;
}
.transaction-filter-search{
  justify-content: flex-end;
}
.PaymentDashboard-transaction-title h5{
  font-family: poppins semibold;
}
.PaymentDashboard-transaction-title select{
  border-radius: 50px;
  padding: 3px 15px;
}
.transaction-search{
  border: 1.5px solid #999;
  border-radius: 50px;
  padding: 3px 15px;
  display: flex;
}
.transaction-search i{
  color: #999;
  align-self: center;
}
.transaction-search input{
  border: none;
  padding: 5px;
  width:100%;
}
.transaction-table-info table{
  margin-top: 15px;
  border-collapse: collapse;
  overflow: hidden;
  border-radius: 5px;
  border: 1px solid #999;
}
.transaction-table-info table th{
  background-color: #11772e;
  color: #fff;
  font-family: poppins medium;
  font-size: 14px ;
  }

.transaction-table-info td{
    font-size: 14px ;
    font-family: poppins medium;
    vertical-align: middle!important;
  }
.transaction-table-info td i{
      color: #7E7E7E;
      font-size: 16px;
  }
.transaction-table-info table tbody tr:nth-child(odd) {
    background-color: #fff;
  }

.transaction-table-info table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  .transaction-table-info table tr .transaction-id p{
    color: #999;
    font-size: 12px;
  }

.payment-paid span, .payment-pending span, .payment-failed span{
  
  color: #fff!important;
  border-radius: 4px;
  padding: 5px 25px;
  font-size: 12px ;
}
.payment-paid span{
  background-color: #24B146;
}
.payment-pending span{
  background-color: #F0BD17;
  color: #333!important;
  padding: 5px 15px;
}
.payment-failed span{
  background-color: #FF1D1D;
  padding: 5px 20px;
}
.transaction-fulldetails{
  padding: 20px;
}
.transaction-fulldetails h6, .transaction-id-modal{
  font-family: poppins medium;
  color: #999;
}
.transaction-fulldetails span{
  color: #000;
}
.transaction-id-modal p{
  margin-bottom: 2px;
  color: #000;
}
.transaction-id-modal p i{
  color: #999;
}
.appointment-details-modal h5{
  font-family: poppins semibold;
  color: #000;
  font-size: 18px;
}
.view-more-transactions p{
  color: #11772e;
  text-decoration: underline;
  cursor: pointer;
}
/* Mobile view */

.m-transaction-data{
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.2);
  padding: 5px 10px;
  cursor: pointer;
}
.m-transaction-charges-status{
  align-self: center;
}
.m-charges-status-paid{
  border-radius: 100px;
  color: #fff;
  width: 70px;
  height: 70px;
  font-size: 12px;
  text-align: center;
  padding-top: 18px;
  font-family: poppins medium;
  background-color: #24B146 ;
}
.m-charges-status-failed{
  border-radius: 100px;
  color: #fff;
  width: 70px;
  height: 70px;
  font-size: 12px;
  text-align: center;
  padding-top: 18px;
  font-family: poppins medium;
  background-color: #FF1D1D ;
}
.m-charges-status-pending{
  border-radius: 100px;
  width: 70px;
  height: 70px;
  font-size: 12px;
  text-align: center;
  padding-top: 18px;
  font-family: poppins medium;
  background-color: #F0BD17 ;
}
.m-appointment-date-time i{
  color: #666;
}
.m-transaction-id{
  font-family: poppins medium;
  font-size: 14px!important;
}
.m-transaction-date-time{
  font-family: poppins regular;
  color: #999;
  font-size: 11px!important;
}
.m-appointment-date-time i{
  font-size: 24px;
}
.m-appointment-date-time{
  font-size: 12px;
  text-align: center;
}
.m-transaction-details{
  align-self: center;
}
.m-transaction-details p, .m-transaction-details span{
  font-size: 12px;
  margin-bottom: 2px;
}


/*Extra Large Screens*/
@media (min-width: 1600px) {
	.mobile-view-table{
    display: none;
  }
}
/*Large Screens*/
@media (min-width: 1200px) and (max-width: 1599px) {
  .mobile-view-table{
    display: none;
  }
}
/*Medium Screens*/
@media (min-width: 992px) and (max-width: 1199px) { 
	.mobile-view-table{
    display: none;
  }
}

/*Small Screens (Portriat Mode)*/
@media (min-width: 768px) and (max-width: 991px) { 
  .large-view-table{
    display:none;
  }
  .transaction-fulldetails {
    padding: 10px;
  }
  .transaction-fulldetails h6, .transaction-id-modal {
    font-family: poppins medium;
    color: #999;
    font-size: 14px;
  }
  .transaction-date-time-modal p{
    padding: 5px;
  }
  .view-more-transactions p{
    font-size: 12px;
  }
  .modal-dialog {
    max-width: 700px;
    margin: 1.75rem auto;
}
}

/*Extra Small Screens (Landscape Mode)*/
@media (min-width: 576px) and (max-width: 767px) { 
  .large-view-table{
    display:none;
  }
  .transaction-fulldetails {
    padding: 10px;
  }
  .transaction-fulldetails h6, .transaction-id-modal {
    font-family: poppins medium;
    color: #999;
    font-size: 14px;
  }
  .transaction-date-time-modal{
    display: flex;
  }
  .transaction-date-time-modal p{
    padding: 5px;
  }
  .view-more-transactions p{
    font-size: 12px;
  }
}

/*Extra Small Screens (Portriat Mode)*/
@media (min-width:320px) and (max-width: 575px){ 
.large-view-table{
  display:none;
}
.PaymentDashboard-title h3{
  font-family: poppins semibold;
  font-size: 22px;
}
.PaymentDashboard-filter select {
  font-size: 13px;
  margin-bottom: 10px!important;
}
.PaymentDashboard-title span {
  color: #999;
  font-size: 13px;
}
.PaymentDashboard-transaction-info{
  padding-left: 20px;
  padding-right: 20px;
}
.total-transaction h3, .successful-transaction h3, .total-payments h3 {
  font-size: 20px;
}
.transaction-filter-search {
  justify-content: center;
  margin-top: 15px;
}
.PaymentDashboard-transaction-title h5 {
  font-family: poppins semibold;
  font-size: 16px;
}
.PaymentDashboard-transaction-title select {
  border-radius: 50px;
  padding: 3px 8px;
  font-size: 14px;
}
.transaction-search {
  font-size: 14px;
}
.appointment-details-modal h6 span{
  display: block;
  padding-top: 10px;
}
.transaction-fulldetails {
  padding: 10px;
}
.transaction-fulldetails h6, .transaction-id-modal {
  font-family: poppins medium;
  color: #999;
  font-size: 14px;
}
.transaction-date-time-modal{
  display: flex;
}
.transaction-date-time-modal p{
  padding: 5px;
}
.view-more-transactions p{
  font-size: 12px;
}
}
@media (max-width:319px){
  .large-view-table{
    display:none;
  }
}