body{
    background-color: #fff;
}

.SuperAdmin_App_summary_table{
    margin-top: 60px;
}
.SuperAdmin_App_summary_table .table-striped tbody tr:nth-of-type(odd) {
    /* background-color: rgba(0,0,0,.05); */
    background-color: rgb(169, 255, 169);
}
.SuperAdmin_App_summary_sortby{
    text-align: end;
}
/*Extra Large Screens*/
@media (min-width: 1600px) {
    .SuperAdmin_App_summary_title{
        font-size:50px; 
        font-weight:800;
        font-family:'poppins extrabold';
        display: flex;
        justify-self: center;
    }
    .SuperAdmin_App_summary_section{
        margin-top: 70px;
    }
    .SuperAdmin_App_summary_dept-name{
        font-family: poppins semibold;
    }
}
/*Large Screens*/
@media (min-width: 1200px) and (max-width: 1599px) {
	
    .SuperAdmin_App_summary_title{
        font-size:50px; 
        font-weight:800;
        font-family:'poppins extrabold';
        display: flex;
        justify-self: center;
    }
    .SuperAdmin_App_summary_section{
        margin-top: 70px;
    }
    .SuperAdmin_App_summary_dept-name{
        font-family: poppins semibold;
    }
}
/*Medium Screens*/
@media (min-width: 992px) and (max-width: 1199px) { 
    .SuperAdmin_App_summary_title{
        font-size:50px; 
        font-weight:800;
        font-family:'poppins extrabold';
    }
    .SuperAdmin_App_summary_section{
        margin-top: 70px;
    }
    .SuperAdmin_App_summary_dept-name{
        font-family: poppins semibold;
    }
}

/*Small Screens (Portriat Mode)*/
@media (min-width: 768px) and (max-width: 991px) { 
    .SuperAdmin_App_summary_title{
        font-size:50px; 
        font-weight:800;
        font-family:'poppins extrabold';
    }
    .SuperAdmin_App_summary_section{
        margin-top: 70px;
    }
    .SuperAdmin_App_summary_dept-name, .SuperAdmin_App_summary_sortby{
        font-family: poppins semibold;
        font-size: 16px;
    }
}

/*Extra Small Screens (Landscape Mode)*/
@media (min-width: 576px) and (max-width: 767px) {
    .SuperAdmin_App_summary_title{
        font-size: 34px; 
        font-weight:800;
        font-family:'poppins extrabold';
    }
    .SuperAdmin_App_summary_section{
        margin-top: 70px;
    }
    
    .SuperAdmin_App_summary_dept-name, .SuperAdmin_App_summary_sortby{
        font-family: poppins semibold;
        text-align: center;
        font-size: 14px;
    }
}

/*Extra Small Screens (Portriat Mode)*/
@media (min-width:320px) and (max-width: 575px){ 
    .SuperAdmin_App_summary_title{
        font-size:26px; 
        font-weight:800;
        font-family:'poppins extrabold';
    }
    .SuperAdmin_App_summary_section{
        margin-top: 70px;
        color: #154686;
    }
    .SuperAdmin_App_summary_dept-name h5, .SuperAdmin_App_summary_sortby{
        font-family: poppins semibold;
        text-align: center;
        font-size: 14px;
    }
}