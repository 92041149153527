.viewAppointment{
    background-color: #ffffff;
}
.superadmin_viewappointment_table tbody tr td{
    vertical-align: middle;
}
/*Extra Large Screens*/
@media (min-width: 1600px) {

}
/*Large Screens*/
@media (min-width: 1200px) and (max-width: 1599px) {
   
}
/*Medium Screens*/
@media (min-width: 992px) and (max-width: 1199px) { 
   
}

/*Small Screens (Portriat Mode)*/
@media (min-width: 768px) and (max-width: 991px) { 
    
}

/*Extra Small Screens (Landscape Mode)*/
@media (min-width: 576px) and (max-width: 767px) { 
    
}

/*Extra Small Screens (Portriat Mode)*/
@media (min-width:320px) and (max-width: 575px){ 
    
}
