.linkRef{
  cursor:pointer;
  color: 	#11772e;
}

.linkRef:hover{
  text-decoration: underline;
  text-decoration-color: #105c25;
  color: #11772e;
}
/*Extra Large Screens*/
@media (min-width: 1600px) {
  .SuperAdmin_ViewDepartments_title{
    font-size:50px; 
    font-weight:800;
    font-family:'poppins extrabold';
  }
}
/*Large Screens*/
@media (min-width: 1200px) and (max-width: 1599px) {
  .SuperAdmin_ViewDepartments_title{
    font-size:50px; 
    font-weight:800;
    font-family:'poppins extrabold';
  }
  
}
/*Medium Screens*/
@media (min-width: 992px) and (max-width: 1199px) { 
  .SuperAdmin_ViewDepartments_title{
    font-size:46px; 
    font-weight:800;
    font-family:'poppins extrabold';
  }
  
}

/*Small Screens (Portriat Mode)*/
@media (min-width: 768px) and (max-width: 991px) { 
  .SuperAdmin_ViewDepartments_title{
    font-size:40px; 
    font-weight:800;
    font-family:'poppins extrabold';
  }
  
}

/*Extra Small Screens (Landscape Mode)*/
@media (min-width: 576px) and (max-width: 767px) { 
  .SuperAdmin_ViewDepartments_title{
    font-size:38px; 
    font-weight:800;
    font-family:'poppins extrabold';
  }
 
}

/*Extra Small Screens (Portriat Mode)*/
@media (min-width:320px) and (max-width: 575px){ 
  .SuperAdmin_ViewDepartments_title{
    font-size:34px; 
    font-weight:800;
    font-family:'poppins extrabold';
  }
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: #5CB04D;
  margin: -4px 0 0 -4px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}