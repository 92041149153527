/*Extra Large Screens*/
@media (min-width: 1600px) {
    .superadmincreatedoc_details{
        margin-left: 25%;
        margin-right: 25%;
    }
    .superadmincreatedoc_savebtn button{
        width: 25%;
        }
    }
    /*Large Screens*/
    @media (min-width: 1200px) and (max-width: 1599px) {
        .superadmincreatedoc_details{
            margin-left: 25%;
            margin-right: 25%;
        }
        .superadmincreatedoc_savebtn button{
            width: 25%;
        }
    }
    /*Medium Screens*/
    @media (min-width: 992px) and (max-width: 1199px) { 
        .superadmincreatedoc_details{
            margin-left: 20%;
            margin-right: 20%;
        }
        .superadmincreatedoc_savebtn button{
            width: 20%;
        }
    }
    
    /*Small Screens (Portriat Mode)*/
    @media (min-width: 768px) and (max-width: 991px) { 
        .superadmincreatedoc_details{
            margin-left: 10%;
            margin-right: 10%;
        }
        .superadmincreatedoc_savebtn button{
            width: 20%;
        }
    }
    
    /*Extra Small Screens (Landscape Mode)*/
    @media (min-width: 576px) and (max-width: 767px) { 
        .superadmincreatedoc_savebtn button{
            width: 25%;
        }
    }
    
    /*Extra Small Screens (Portriat Mode)*/
    @media (min-width:320px) and (max-width: 575px){ 
        .superadmincreatedoc_savebtn button{
        width: 40%;
        }
    }
    
    #profile-upload{
        background-image:url('./hospital.jpg');
        background-size:cover;
        background-position: center;
        height: 150px; width: 150px;
        border: 1px solid #bbb;
        position:relative;
      border-radius:250px;
      overflow:hidden;
    }
    #profile-upload:hover input.upload{
      display:block;
    }
    #profile-upload:hover .hvr-profile-img{
      display:inline-block;
     }
    #profile-upload .fa{   margin: auto;
        position: absolute;
        bottom: -4px;
        left: 0;
        text-align: center;
        right: 0;
        padding: 6px;
      /* opacity:0.5;*/
       color: transparent;
       background-color: #d3ecff;
     /* transition:opacity 1s linear;*/
       -webkit-transform: scale(.75); 
     
     
    }
    #profile-upload:hover .fa{
      /* opacity:1;*/
       height: 50%;
       color: white;
       font-size: 16px;
       background-color: #d3ecff;
     /*  transition:opacity 1s linear;*/
       cursor: pointer;
       -webkit-transform: scale(1); 
    }
    #profile-upload input.upload {
        z-index:1;
        left: 0;
        margin: 0;
        bottom: 0;
        top: 0;
        padding: 0;
        opacity: 0;
        outline: none;
        cursor: pointer;
        position: absolute;
        background:#d3ecff;
        width:100%;
        display:none;
    }
    
    #profile-upload .hvr-profile-img {
      width:100%;
      height:100%;
      display: none;
      position:absolute;
      vertical-align: middle;
      position: relative;
      background: transparent;
     }
    #profile-upload .fa:after {
        content: "";
        position:absolute;
        bottom:0; left:0;
        width:100%; height:0px;
        background:rgba(0,0,0,0.3);
        z-index:-1;
        transition: height 0.3s;
        }
    
    #profile-upload:hover .fa:after { height:100%; }


    #profile-upload1{
        /* background-image:url('./hospital.jpg');
         background-size:cover;
         background-position: center;*/
         height: 150px; width: 150px;
         border: 1px solid #bbb;
         position:relative;
       border-radius:250px;
       overflow:hidden;
      }
      #profile-upload1:hover input.upload{
        display:block;
      }
      #profile-upload1:hover .hvr-profile-img1{
        display:inline-block;
       }
      #profile-upload1 .fa{   margin: auto;
          position: absolute;
          bottom: -4px;
          left: 0;
          text-align: center;
          right: 0;
          padding: 6px;
        /* opacity:0.5;*/
         color: transparent;
         background-color: #d3ecff;
       /* transition:opacity 1s linear;*/
         -webkit-transform: scale(.75); 
       
       
      }
      #profile-upload1:hover .fa{
        /* opacity:1;*/
         height: 50%;
         color: white;
         font-size: 16px;
         background-color: #d3ecff;
       /*  transition:opacity 1s linear;*/
         cursor: pointer;
         -webkit-transform: scale(1); 
      }
      #profile-upload1 input.upload {
          z-index:1;
          left: 0;
          margin: 0;
          bottom: 0;
          top: 0;
          padding: 0;
          opacity: 0;
          outline: none;
          cursor: pointer;
          position: absolute;
          background:#d3ecff;
          width:100%;
          display:none;
      }
      
      #profile-upload1 .hvr-profile-img {
        width:100%;
        height:100%;
        display: none;
        position:absolute;
        vertical-align: middle;
        position: relative;
        background: transparent;
       }
      #profile-upload1 .fa:after {
          content: "";
          position:absolute;
          bottom:0; left:0;
          width:100%; height:0px;
          background:rgba(0,0,0,0.3);
          z-index:-1;
          transition: height 0.3s;
          }
      
      #profile-upload1:hover .fa:after { height:100%; }