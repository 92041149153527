.signin{
  background-image: url('./img/Admin-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  overflow: hidden;
  max-width: 100%;
  min-width: 100%;
  max-height: 100vh;
  min-height: 100vh;
}
.Admin_login_page{
  min-height: 100vh;
  font-family: poppins;
}
.Admin_login_page .navbar-section{
  padding: 20px 40px;
}
.Admin_login_pageform{
  border-radius: 10px;
  background-color:rgba(255, 255, 255, 0.3) ;
  box-shadow: 3px 2px 10px rgba(0, 0, 0, 0.3);
}

.Admin_login_pageform h3{
  font-family: poppins;
  font-weight: 700;
  color: #4a4a4a;
  font-size: 34px;
}
.Admin_login_pageform span{
  font-family: poppins medium;
  color: #6f6f6f;
  color:#999;
}
.Admin_login_pageform .email input, .Admin_login_pageform .password input{
  overflow: hidden;
  padding: 13px;
  width: 100%;
  border: none;
  background-color: transparent;
}
.Admin_login_page input:focus,.Admin_login_page button:focus{
  outline: -webkit-focus-ring-color auto 0px;
}
.Admin_login_pageform i{
  color: #999;
  font-size: 18px;
  align-self: center;
  padding: 10px;
}
.Admin_login_pageform .email, .Admin_login_pageform .password{
  background-color: #fff;
  border: 2px solid #999;
}
.Admin_login_pageform .button button{
  font-family: poppins medium;
  border: 2px solid #fff;
  border-radius: 5px;
  background-color: #105c25;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  padding: 5px;
  color: #fff;
  text-align: center;
  width: 100%;
}
.Admin_login_pageform .button button:hover{
  background-color: #11772e;
}

.Admin_login_page .forgot_password a,.Admin_login_page .remember-password{
  font-family: poppins regular;
  color:#1f2123;
}
.Admin_login_page_bg{
  text-align: end;
  align-self: center;
}
.Admin_login_page .footer-section{
  font-family: poppins medium;
  font-size: 12px;
  background-color: rgba(255, 255, 255, .7);
}
.Admin_login_page .footer-section a{
  color:#1f2123;
}
/*Extra Large Screens*/
@media (min-width: 1600px) {
	.Admin_login_pageform{
    padding: 50px;
  }
  .Admin_login_page .terms-privacy{
    display: flex;
    justify-content: flex-end;
  }
  .Admin_login_page .footer-section{
  position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
}
/*Large Screens*/
@media (min-width: 1200px) and (max-width: 1600px) {
  .Admin_login_pageform{
    padding: 50px;
  }
  .Admin_login_page .terms-privacy{
    display: flex;
    justify-content: flex-end;
  }
  .Admin_login_page .footer-section{
  position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
}
/*Medium Screens*/
@media (min-width: 992px) and (max-width: 1200px) { 
  .Admin_login_page_logindetails{
    margin: 40px 70px;
  }
  .Admin_login_pageform{
    padding: 30px;
  }
  .Admin_login_page .terms-privacy{
    display: flex;
    justify-content: flex-end;
  }
  .Admin_login_page .footer-section{
  position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
}

/*Small Screens (Portriat Mode)*/
@media (min-width: 768px) and (max-width: 992px) { 
  .Admin_login_page{
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .Admin_login_page_logindetails{
    margin: 40px 130px;
  }
  .Admin_login_pageform{
    padding: 50px;
  }
  .Admin_login_page_bg{
    display: none;
  }
  .Admin_login_page .navbar-section{
    padding: 10px 20px;
  }
  .Admin_login_page .terms-privacy{
    display: flex;
    justify-content: flex-end;
  }
  .Admin_login_page .footer-section{
  position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  .Admin_login_page .footer-content{
    text-align: center;
  }
}

/*Extra Small Screens (Landscape Mode)*/
@media (min-width: 576px) and (max-width: 768px) { 
  
  .Admin_login_page{
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .Admin_login_page_logindetails{
    margin: 40px 20px;
  }
  .Admin_login_pageform{
    padding: 40px;
  }
  .Admin_login_page_bg{
    display: none;
  }
  .Admin_login_page .Casemedcare_logo img{
    width: 130px;
  }
  .Admin_login_page .navbar-section{
    padding: 10px 20px;
  }
  .Admin_login_page .footer-content{
    text-align: center;
  }
}

/*Extra Small Screens (Portriat Mode)*/
@media (min-width:320px) and (max-width: 576px){ 
  .Admin_login_page{
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .Admin_login_page_logindetails{
    margin: 40px 5px;
  }
  .Admin_login_pageform{
    padding: 30px;
  }
  .Admin_login_page_bg{
    display: none;
  }
  .Admin_login_page .Casemedcare_logo img{
    width: 130px;
  }
  .Admin_login_page .navbar-section{
    padding: 10px 20px;
  }
  .Admin_login_page .footer-content{
    text-align: center;
  }
}


